.nav-bar {
  background-color: transparent;
}

.hover-blue:hover {
  color: rgb(32, 160, 211);
}

.hover-pink:hover {
  color: rgb(236, 76, 79);
}

.hover-dblue:hover {
  color: rgb(57, 84, 152);
}

.hover-black:hover {
  color: rgb(30, 129, 242);
}

.hover-lblue:hover {
  color: rgb(0, 119, 181);
}

.trans {
  transition: 0.2s;
}
