@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&family=Roboto:wght@500&display=swap");

.fixed-bg {
  background-image: url("../assets/beard4.jpg");
  min-height: 100vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.centered {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.border-dotted {
  border: 2px dotted black;
}

.margin-right {
  margin-right: 2rem;
}

.veni {
  margin-top: 4rem;
  @media only screen and (min-width: 300px) and (max-width: 900px) {
    margin-top: 1rem;
  }
}

h1 {
  font-size: 4rem;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 1rem;
}
.name {
  font-size: 4rem;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 1rem;
  @media only screen and (min-width: 300px) and (max-width: 900px) {
    font-size: 3rem;
    padding-left: 2rem;
  }
}

.monst {
  font-family: "Montserrat", sans-serif;
}

.monstb {
  font-family: "Montserrat", sans-serif;
  font-weight: bolder;
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

.para {
  box-sizing: border-box;
  padding: 0.1rem;
}
.profdiv {
  text-align: left;
  text-align-last: left; /* For Edge */
}

.btn-hvr:hover {
  background-color: green;
}
.btn {
  background-color: white;
  color: black;
  font-weight: normal;
}

.text-gray {
  color: rgb(70, 66, 66);
  font-weight: bold;
}
.gray {
  color: gray;
}
.underline {
  text-decoration: underline;
}

.hw {
  width: 10rem;
  height: 15rem;
}

.m-10 {
  margin-bottom: 10rem;
}

.chevron {
  position: absolute;
  width: 28px;
  height: 8px;
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
  animation: move 3s ease-out infinite;
}

.chevron:first-child {
  animation: move 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
  animation: move 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
  content: " ";
  position: absolute;
  top: 0;
  height: 100%;
  width: 51%;
  background: #fff;
}

.chevron:before {
  left: 0;
  transform: skew(0deg, 30deg);
}

.chevron:after {
  right: 0;
  width: 50%;
  transform: skew(0deg, -30deg);
}

@keyframes move {
  25% {
    opacity: 1;
  }
  33% {
    opacity: 1;
    transform: translateY(30px);
  }
  67% {
    opacity: 1;
    transform: translateY(40px);
  }
  100% {
    opacity: 0;
    transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
  }
}

.text {
  display: block;
  margin-top: 75px;
  margin-left: -30px;
  font-family: "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
  white-space: nowrap;
  opacity: 0.25;
  animation: pulse 2s linear alternate infinite;
}

@keyframes pulse {
  to {
    opacity: 1;
  }
}

.centered-chv {
  position: absolute;
  top: 150%;
  left: 48%;
  transform: translate(-50%, -50%);
  @media only screen and (min-width: 300px) and (max-width: 900px) {
    left: 43%;
  }
}

.imghvr {
  transition: 0.3s;
}

.imghvr:hover {
  cursor: pointer;
  width: 220px;
  height: 220px;
}
.errorbuttonhover:hover {
  cursor: no-drop;
}
