@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&family=Roboto:wght@500&display=swap");

.App {
  text-align: center;
  box-sizing: border-box;
}
body {
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fixed-bg {
  background-image: url("../src/assets/beard4.jpg");
  min-height: 100vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right center;
  @media only screen and (min-width: 300px) and (max-width: 900px) {
    background-image: url("../src/assets/beard44.jpg");
    background-position: right center;
  }
}
/* https://i.imgur.com/Yj15qi8.png */
/* https://i.imgur.com/Yj15qi8.png */
/* https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png */
/* https://i.pinimg.com/564x/87/8c/e8/878ce8b83bcc9718d86f30d8b7d5f727.jpg */
/* https://i.pinimg.com/564x/7b/4c/38/7b4c38764d91ca35ba11b31156ac2094.jpg */
/* https://i.pinimg.com/564x/aa/7d/ef/aa7defb28c528954ab89cf638c0ecaf7.jpg */
.fixed-bg2 {
  background-image: url("https://i.pinimg.com/564x/7b/4c/38/7b4c38764d91ca35ba11b31156ac2094.jpg");
  min-height: 100vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  background-size: initial;

  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
  background-position: right center;
}

.fixed-bg3 {
  background-image: url("../src/assets/anon4.jpg");
  min-height: 35vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right center;
  @media only screen and (min-width: 300px) and (max-width: 900px) {
    background-image: url("../src/assets/anon444.jpg");
    background-position: right center;
    min-height: 32vh;
  }
}

.fixed-bg4 {
  min-height: 100vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #e3e3e3;
  background-position: right center;
}

.fixed-bg5 {
  background-image: url("https://i.imgur.com/Yj15qi8.png");
  min-height: 100vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  background-size: initial;

  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
  background-position: right center;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: gray;
}

::-webkit-scrollbar-thumb:hover {
  background: black;
}
